<style lang="less" scoped src="./index.less"></style>
<template src="./index.html"></template>
<script>
import selfPopup from "@/components/self_popup";
import { mapState } from "vuex";

export default {
  name: "guide_kuaishou",
  components:{selfPopup},
  computed: { 
      ...mapState({
        douyin_auth_info: (state) => state.setting.douyin_auth_info,
      }),
  },
  data(){
    return{
      show_page: false,
      auth_data: {},
      auth_popup: false,
    }
  },
  mounted() {
    document.getElementById("app").style = "background: #F5F5F4"
    this.$store.dispatch('setting/getDYRelationInfo').then(res=>{
      console.log(res)
      this.show_page = true
    })
  },
  destroyed() {
    document.getElementById("app").style = "background: #ffffff"
  },
  methods: {
    open_page(url){
      window.open(url)
    },
    goto_auth(url){
      window.open(url)
      this.auth_popup = true
    },
    auth_popup_button(){
      window.location.reload()
    }
  },
}
</script>
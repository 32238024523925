<template>
  <div class="appDialog">
    <!-- 提示安装打印控件 -->
    <el-dialog
      :visible="true"
      width="30%"
      background-color='#fff'
      :show-close = '!!showClose'
      :before-close="closeIcon"
      center>
      <div class="contentClass3">
        <div class="content_box">
          <i v-if="!icon" class="el-icon-warning"></i>
          <i v-else :class="icon"></i>
          <div class="content_box_text">{{text}}</div>
        </div>
        <div class="contentFooter">
          <div @click="confirm" style="border: 1px solid #ff7614;">{{confirmText||"确认"}}</div>
          <div v-if="!showCancel" @click="cancel">{{cancelText||"取消"}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "appDialog",
  data() {
    return {
    };
  },
  props:['confirmText','cancelText','text','icon','showCancel','showClose'],

  methods: {
    confirm() {
      this.$emit("confirm")
    },
    closeIcon(){
      this.$emit("cancel")
    },
    cancel(){
      this.$emit("cancel")
    }

  },
};
</script>

<style lang="less" scoped>
.appDialog {
  /deep/ .el-dialog {
    background-color: #fff;
    border-radius: 6px;
  }
  /deep/ .el-dialog__header {
      padding: 0;
  }
  /deep/ .el-dialog__body {
      padding: 0;
      border-radius: 6px;
  }
  /deep/ .el-dialog__headerbtn {
    top: 10px;
    right: 12px;
  }
  /deep/ .el-dialog__close{
      font-size: 20px;
      font-weight: 400;
      // color: #2c2b3b;
      color: #999;
  }
  /deep/ .el-dialog__close:hover {
      color: #2c2b3b;
  }
  .contentClass3 {
    color: #000;
    padding: 15px 25px;
    .content_box{
      padding-top: 20px;
      display: flex;
      align-items: start;
    }
    .refreshBtn {
      font-size:14px;
      line-height:14px;
      color:#3366cc;
      margin:0;
      text-decoration:underline;
      cursor: pointer;
    }
    i {
      font-size: 26px;
      line-height: 26px;
      color: #f00;
    }
    .content_box_text {
      flex:1;
      font-size: 18px;
      line-height: 1.6;
      font-weight: 600;
      color: #000;
      margin-left: 10px;
    }
    div:nth-child(1) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin-left: 36px;
      }
    }
    .contentFooter {
      width: 100%;
      text-align: right;
      margin-top: 40px;
      div {
        background-color: #ff7614;
        padding: 5px 18px;
        display: inline-block;
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
      }
      div:nth-child(2) {
        background-color: #fff;
        border: 1px  solid #b4b4b4;
        color: #333;
        margin-left: 10px;
      }
    }
  }
}
</style>